<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"
import List from '../../components/partner/list'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List
  },
  data() {
    return {
      title: "",
      level: '',
      state: 'normal'
    }
  },
  created() {
    this.level = this.$route.params.level
  },
  mounted() {
    let title = '파트너&nbsp'
    if (this.level === 'top') {
      title += '<span class="badge bg-primary">' + this.$t('본사') + '</span>'
    } else if (this.level === 'super') {
      title += '<span class="badge bg-success">' + this.$t('부본사') + '</span>'
    } else if (this.level === 'master') {
      title += '<span class="badge bg-info">' + this.$t('지사') + '</span>'
    } else if (this.level === 'agent') {
      title += '<span class="badge bg-warning">' + this.$t('총판') + '</span>'
    } else if (this.level === 'shop') {
      title += '<span class="badge bg-secondary">' + this.$t('매장') + '</span>'
    }
    this.title = title
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <List :level="level" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
