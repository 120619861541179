<script>
import DatePicker from "@vuepic/vue-datepicker"
import BadgeLevel from '@/components/badge-level'
import BadgeState from '@/components/badge-state'
import VPagination from '@hennge/vue3-pagination'
import '/src/assets/styles/pagination.default.css'
import AddChildren from './add-children'
import PopupPartner from './popup'

export default {
  components: {
    DatePicker,
    PopupPartner,
    BadgeLevel,
    BadgeState,
    VPagination,
    AddChildren
  },
  props: {
    level: String,
    state: String
  },
  data() {
    return {
      lt:0,
      search: {
        groupKey: '',
        searchType: 'username',
        searchText: '',
        beginDate: '',
        endDate: '',
        state: ''
      },
      sort: { createdAt: -1 },
      list: [],
      limit: 50,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1 // 현재 페이지
    }
  },
  mounted () {

    this.lt = new Date().getTime()
    /*
    let beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 365)
    let endDate = new Date()

    this.search.beginDate = beginDate
    this.search.endDate = endDate
     */

    const a = localStorage.getItem('limit')
    this.limit = (a !== 'NaN' && a) ? parseInt(a) : 50

    this.searchList().then()
  },
  methods: {
    refresh: function() {
      document.location.reload()
    },
    addChildren: function(parent) {
      this.$refs['modal-add-children'].open(parent)
    },
    setSort: function(field) {
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort = {}
      this.sort[field] = dir
      // this.searchList().then()
    },
    searchByPage: function(page) {
      this.page = page
      this.searchList().then()
    },
    searchList: async function() {
      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        localStorage.setItem('limit', this.limit)

        const params = {
          search: { state: this.state, level: this.level },
          sdate: this.search.beginDate,
          edate: this.search.endDate,
          sort: this.sort,
          limit: this.limit,
          page: this.page
        }
        params.search[this.search.searchType] = this.search.searchText
        params.search.state = this.state

        const result = await this.$API.partner.list(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = result.documents
        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount ? result.totalPageCount : 1
        // this.page = result.page
      } catch(e) {
        alert(this.$t('text.error_post'))
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    }
  }
}
</script>

<template>
  <div ref="contentWrapper">
    <form class="header-search" @submit.prevent="searchList">
      <div class="row">
        <div class="form-group col-md-3 mb-2">
          <DatePicker
              v-model="search.beginDate"
              :format="'yyyy-MM-dd HH:mm:ss'"
              utc
              auto-apply
              :close-on-auto-apply="false"
              :placeholder="$t('시작일')"
          ></DatePicker>
        </div>

        <div class="form-group col-md-3 mb-2">
          <DatePicker
              v-model="search.endDate"
              :format="'yyyy-MM-dd HH:mm:ss'"
              utc
              auto-apply
              :close-on-auto-apply="false"
              :placeholder="$t('종료일')"
          ></datepicker>
        </div>

        <div class="form-group col-md-1 mb-2">
          <select class="form-select" v-model="search.searchType">
            <option value="username">{{ $t('아이디') }}</option>
            <option value="siteUsername">{{ $t('사이트 아이디') }}</option>
            <!--<option value="siteUsername" v-if="$store.getters['auth/user'].type === 'company'">{{ $t('파트너아이디') }}</option>-->
          </select>
        </div>

        <div class="form-group col-md-2 mb-2">
          <input type="text" class="form-control" v-model="search.searchText" :placeholder="$t('검색어')" />
        </div>

        <div class="col-md-1 mb-1">
          <select class="form-select" v-model="limit" @change="searchList">
            <option :value="10">10개</option>
            <option :value="25">25개</option>
            <option :value="50">50개</option>
            <option :value="100">100개</option>
          </select>
        </div>

        <div class="form-group col-md-1 mb-2">
          <button type="submit" class="btn btn-dark w-100">검색</button>
        </div>
        <div class="form-group col-md-1 mb-2">
          <button class="btn btn-warning w-100" type="button" @click="addChildren($store.getters['auth/user'])" v-if="$store.getters['auth/user'].type === 'admin' && level === 'top'">{{$t('본사 생성')}}</button>
          <button class="btn btn-warning w-100" type="button" @click="addChildren($store.getters['auth/user'])" v-if="$store.getters['auth/user'].type === 'partner' && $store.getters['auth/user'].level === 'top' && level === 'super'">{{$t('부본사 생성')}}</button>
          <button class="btn btn-warning w-100" type="button" @click="addChildren($store.getters['auth/user'])" v-if="$store.getters['auth/user'].type === 'partner' && $store.getters['auth/user'].level === 'super' && level === 'master'">{{$t('지사 생성')}}</button>
          <button class="btn btn-warning w-100" type="button" @click="addChildren($store.getters['auth/user'])" v-if="$store.getters['auth/user'].type === 'partner' && $store.getters['auth/user'].level === 'master' && level === 'agent'">{{$t('총판 생성')}}</button>
          <button class="btn btn-warning w-100" type="button" @click="addChildren($store.getters['auth/user'])" v-if="$store.getters['auth/user'].type === 'partner' && $store.getters['auth/user'].level === 'agent' && level === 'shop'">{{$t('매장 생성')}}</button>
        </div>
      </div>

    </form>

    <hr class="mt-0 mb-3" />

    <div class="table-responsive rounded-3 w-100" style="min-height: 800px;">
      <table class="table table-nowrap table-centered align-middle table-striped sticky-table">
        <thead class="table-dark">
        <tr>
          <th scope="col">
            <a href="javascript:void(0)" @click="setSort('createdAt')">비고</a>&nbsp;
            <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
          </th>
          <th scope="col">{{ $t('상위') }}</th>
          <th scope="col">{{ $t('등급') }}</th>
          <th scope="col" style="min-width: 300px;">{{ $t('아이디') }}</th>
          <th scope="col">{{ $t('수수료') }}</th>
          <th scope="col">{{ $t('상태') }}</th>
          <th scope="col" :hidden="$store.getters['auth/user'].type !== 'admin'">{{ $t('에볼루션') }}</th>
          <th scope="col">
            <a href="javascript:void(0)" @click="setSort('cash')">{{ $t('보유캐쉬') }}</a>&nbsp;
            <template v-if="sort.field === 'cash'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
          </th>
          <th scope="col">{{ $t('하부보유캐쉬') }}

          </th>
          <!--
          <th scope="col">{{ $t('회원캐쉬') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="회원들의 총 보유캐쉬"></span></th>
          <th scope="col">{{ $t('전체캐쉬') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="본인 포함한 하부 총 보유캐쉬"></span></th>
          <th scope="col">{{ $t('지급/회수') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="본인이 상부로부터 지급 받거나 회수된 캐쉬"></span></th>
          <th scope="col">{{ $t('하위') }} {{ $t('지급/회수') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="본인이 하부에게 지급하거나 회수한 캐쉬"></span></th>

          <th scope="col">
            <a href="javascript:void(0)" @click="setSort('t2.bet')">배팅</a>
            <template v-if="sort['t2.bet']"><span class="fa fa-sort-amount-down" v-if="sort['t2.bet'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.bet'] === 1"></span></template>
          </th>
          <th scope="col">
            <a href="javascript:void(0)" @click="setSort('t2.draw')">무</a>
            <template v-if="sort['t2.draw']"><span class="fa fa-sort-amount-down" v-if="sort['t2.draw'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.draw'] === 1"></span></template>
          </th>
          <th scope="col">
            <a href="javascript:void(0)" @click="setSort('t2.win')">승</a>
            <template v-if="sort['t2.win']"><span class="fa fa-sort-amount-down" v-if="sort['t2.win'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.win'] === 1"></span></template>
          </th>
          <th scope="col">
            <a href="javascript:void(0)" @click="setSort('t2.cancel')">취소</a>
            <template v-if="sort['t2.cancel']"><span class="fa fa-sort-amount-down" v-if="sort['t2.cancel'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.cancel'] === 1"></span></template>
          </th>
          <th scope="col" @click="setSort('t2.valid')">
            <a href="javascript:void(0)" @click="setSort('t2.valid')">유효배팅</a>
            <template v-if="sort['t2.valid']"><span class="fa fa-sort-amount-down" v-if="sort['t2.valid'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.valid'] === 1"></span></template>
          </th>
          <th scope="col" @click="setSort('t2.winlose')">
            <a href="javascript:void(0)" @click="setSort('t2.winlose')">수익금</a>
            <template v-if="sort['t2.winlose']"><span class="fa fa-sort-amount-down" v-if="sort['t2.winlose'] === -1"></span><span class="fa fa-sort-amount-up" v-if="sort['t2.winlose'] === 1"></span></template>
          </th>

          <th scope="col">{{ $t('하부') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="보유 총 파트너수"></span></th>
          <th scope="col">{{ $t('게임사') }} <span class="fa fa-question-circle" v-b-tooltip.hover data-placement="top" title="설정된 게임사수"></span></th>
          //-->
          <th scope="col">
            <a href="javascript:void(0)" @click="setSort('updatedAt')">{{ $t('수정일') }}</a>&nbsp;
            <template v-if="sort.field === 'updatedAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
          </th>
          <th scope="col">
            <a href="javascript:void(0)" @click="setSort('createdAt')">{{ $t('시간') }}</a>&nbsp;
            <template v-if="sort.field === 'createdAt'"><span class="fa fa-sort-amount-down" v-if="sort.directive === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.directive === 1"></span></template>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="record in list" :key="record.id">
          <template v-if="record.id !== $store.getters['auth/user'].id">
            <!-- 메뉴 //-->
            <td>
              <popup-partner :partner="record" @sl="searchList" @rf="refresh"></popup-partner>
            </td>
            <td class="text-start">




              <router-link :to="'/partner/detail/' + record.top.id" target="_blank" v-if="record.top && record.top.id !== record.id &&  ($store.getters['auth/user'].level != 'super' && $store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><badge-level :partner="record.top" :show-name="true"></badge-level></router-link>
              <router-link :to="'/partner/detail/' + record.super.id" target="_blank" v-if="record.super && record.super.id !== record.id && ($store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><badge-level :partner="record.super" :show-name="true"></badge-level></router-link>
              <router-link :to="'/partner/detail/' + record.master.id" target="_blank" v-if="record.master && record.master.id !== record.id && ($store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><badge-level :partner="record.master" :show-name="true"></badge-level></router-link>
              <router-link :to="'/partner/detail/' + record.agent.id" target="_blank" v-if="record.agent && record.agent.id !== record.id && ($store.getters['auth/user'].level != 'shop')"><badge-level :partner="record.agent" :show-name="true"></badge-level></router-link>

              <!--
                            <badge-level :show-name="true" :partner="record.top" v-if="record.top && record.top.id !== record.id &&  ($store.getters['auth/user'].level != 'top' && $store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"></badge-level>
                            <badge-level :show-name="true" :partner="record.super" v-if="record.super && record.super.id !== record.id && ($store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"></badge-level>
                            <badge-level :show-name="true" :partner="record.master" v-if="record.master && record.master.id !== record.id && ($store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"></badge-level>
                            <badge-level :show-name="true" :partner="record.agent" v-if="record.agent && record.agent.id !== record.id && ($store.getters['auth/user'].level != 'shop')"></badge-level>
                            -->
            </td>
            <!-- 등급 //-->
            <td class="text-start">
              <div class="row">
                <div class="col-md-12">
                  <badge-level :show-name="false" :partner="record"></badge-level>
                </div>
              </div>
            </td>
            <!-- 정보 //-->
            <td class="text-start">
              <div class="row">
                <div class="col-md-12">
                  <span class="ms-1"><router-link :to="'/partner/detail/' + record.id" target="_blank">{{ record.username }}<small> [{{ record.nickname }}] [{{ record.prefix }}]</small></router-link></span>
                </div>
              </div>
                <div class="row" v-if="record.memo">
                    <div class="col-md-12 border-top mt-2 pt-1 small text-secondary">
                        {{ record.memo }}
                    </div>
                </div>
            </td>
            <!-- prefix //-->
            <!-- 알 지급율 //-->
            <td>
              {{ record.cashRate }}%
            </td>
            <!-- 상태 //-->
            <td>
              <badge-state :user="record"></badge-state>
            </td>
            <!-- 에볼루션 스위치 //-->
            <td :hidden="$store.getters['auth/user'].type !== 'admin'">
              <span class="badge bg-success p-1" v-if="record.useEvo == 1">활성</span>
              <span class="badge bg-danger p-1" v-else>비활성</span>
            </td>
            <!-- 보유캐쉬 //-->
            <td class="text-end">
              <!-- div><span class="badge bg-success">어제</span> {{ record.yesterdayCash.floatVal(2).formatThousands() }}</div>
              <div><span class="badge bg-primary">금일</span> {{ record.cash.floatVal(2).formatThousands() }}</div> //-->
              {{ record.cash.floatVal(2).formatThousands() }}
            </td>
            <!-- 하부캐쉬 //-->
            <td class="text-end">
              <!-- <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.partnerCash.floatVal(2).formatThousands() }}</div>
              <div><span class="badge bg-primary">금일</span> {{ record.partnerCash.floatVal(2).formatThousands() }}</div> //-->
              {{ record.partnerCash.floatVal(2).formatThousands() }}
            </td>
            <!-- 회원캐쉬 //-->
            <!--
            <td class="text-end">
               <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.userCash.floatVal(2).formatThousands() }}</div>
               <div><span class="badge bg-primary">금일</span> {{ record.userCash.floatVal(2).formatThousands() }}</div>
              {{ record.userCash.floatVal(2).formatThousands() }}
            </td>
            -->
            <!-- 전체캐쉬 //-->
            <!--
            <td class="text-end">
              <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ (record.stat.userCash + record.stat.partnerCash + record.yesterdayCash).floatVal(2).formatThousands() }}</div>
              <div><span class="badge bg-primary">금일</span> {{ (record.cash + record.partnerCash + record.userCash).floatVal(2).formatThousands() }}</div>
              {{ (record.cash + record.partnerCash + record.userCash).floatVal(2).formatThousands() }}

            </td>
            -->
            <!-- 본인 지급/회수 //-->
            <!--
            <td>
              <div class="text-primary text-end">{{ record.myReceiveCash.floatVal(2).formatThousands() }}</div>
              <div class="text-danger text-end">{{ record.mySendCash.floatVal(2).formatThousands() }}</div>
              <div class="border-1 border-bottom border-secondary"></div>
              <div class="text-success text-end">{{ (record.myReceiveCash.floatVal(2) - record.mySendCash.floatVal(2)).floatVal(2).formatThousands() }}</div>
            </td>
            -->
            <!-- 하위 지급/회수 //-->
            <!--
            <td>
              <div class="text-primary text-end">{{ record.sendCash.floatVal(2).formatThousands() }}</div>
              <div class="text-danger text-end">{{ record.receiveCash.floatVal(2).formatThousands() }}</div>
              <div class="border-1 border-bottom border-secondary"></div>
              <div class="text-success text-end">{{ (record.sendCash.floatVal(2) - record.receiveCash.floatVal(2)).formatThousands() }}</div>
            </td>
            -->
            <!--
            <td class="text-end">
              <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.bet.floatVal(2).formatThousands() }}</div>
              <div><span class="badge bg-primary">금일</span> {{ record.bet.floatVal(2).formatThousands() }}</div>
            </td>

            <td class="text-end">
              <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.draw.floatVal(2).formatThousands() }}</div>
              <div><span class="badge bg-primary">금일</span> {{ record.draw.floatVal(2).formatThousands() }}</div>
            </td>

            <td class="text-end">
              <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.win.floatVal(2).formatThousands() }}</div>
              <div><span class="badge bg-primary">금일</span> {{ record.win.floatVal(2).formatThousands() }}</div>
            </td>

            <td class="text-end">
              <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.cancel.floatVal(2).formatThousands() }}</div>
              <div><span class="badge bg-primary">금일</span> {{ record.cancel.floatVal(2).formatThousands() }}</div>
            </td>

            <td class="text-end">
              <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.valid.floatVal(2).formatThousands() }}</div>
              <div><span class="badge bg-primary">금일</span> {{ record.valid.floatVal(2).formatThousands() }}</div>
            </td>
            <td class="text-end">
              <div v-if="record.stat"><span class="badge bg-success">어제</span> {{ record.stat.winlose.floatVal(2).formatThousands() }}</div>
              <div><span class="badge bg-primary">금일</span> {{ record.winlose.floatVal(2).formatThousands() }}</div>
            </td>
            -->
            <!--
            <td>

              <router-link v-if="record.superCount === 0 || record.superCount" :to="'/partner/level/super?_t=' + this.lt" class="side-nav-link-ref">
                <span class="badge bg-success me-1" v-b-tooltip.hover data-placement="top" title="대본사">{{ record.superCount.formatThousands() }}</span>
              </router-link>

              <router-link v-if="record.masterCount === 0 || record.masterCount" :to="'/partner/level/master?_t=' + this.lt" class="side-nav-link-ref">
                <span class="badge bg-info me-1" v-b-tooltip.hover data-placement="top" title="부본사">{{ record.masterCount.formatThousands() }}</span>
              </router-link>

              <router-link v-if="record.agentCount === 0 || record.agentCount" :to="'/partner/level/agent?_t=' + this.lt" class="side-nav-link-ref">
                <span class="badge bg-warning me-1" v-b-tooltip.hover data-placement="top" title="총판">{{ record.agentCount.formatThousands() }}</span>
              </router-link>

              <router-link v-if="record.shopCount === 0 || record.shopCount" :to="'/partner/level/shop?_t=' + this.lt" class="side-nav-link-ref">
                <span class="badge bg-dark me-1" v-b-tooltip.hover data-placement="top" title="매장">{{ record.shopCount.formatThousands() }}</span>
              </router-link>
              <router-link v-if="record.userCount === 0 || record.userCount" :to="'/user/state/normal?_t=' + this.lt" class="side-nav-link-ref">
                <span class="badge bg-secondary" v-b-tooltip.hover data-placement="top" title="회원">{{ record.userCount.formatThousands() }}</span>
              </router-link>
            </td>
            //-->
            <!-- 게임사 갯수 //-->
            <!--
            <td>
              <span class="badge bg-info">{{ record.vendorCount.formatThousands() }}</span>
            </td>
            -->
            <!-- 업데이트 일자 //-->
            <td class="small"><span v-b-tooltip.hover data-placement="top" :title="record.updatedAt">{{ new Date(record.updatedAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</span></td>
            <!-- 생성일자 //-->
            <td class="small"><span v-b-tooltip.hover data-placement="top" :title="record.createdAt">{{ new Date(record.createdAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</span></td>
          </template>
        </tr>
        </tbody>
      </table>
    </div>

    <v-pagination
        class="justify-content-center mb-2"
        v-model="page"
        :pages=totalPageCount
        :range-size="2"
        active-color="#DCEDFF"
        @update:modelValue="searchByPage"
    />

    <!-- 하부 생성 //-->
    <AddChildren ref="modal-add-children" @searchList="refresh" />
  </div>
</template>
